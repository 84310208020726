var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "mt-16"
  }, [_c('v-card', {
    staticClass: "rounded-lg elevation-0"
  }, [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_c('v-spacer'), _vm.hasApiDeveloperRight ? _c('v-btn', {
    staticClass: "mr-2 rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isTranslationAddModalActive = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPlus))]), _vm._v(" " + _vm._s(_vm.$t('form.action.addTranslation')) + " ")], 1) : _vm._e(), _vm.hasCreateRight ? _c('v-btn', {
    staticClass: "mr-2 rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isLocaleAddModalOpen = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiPlus))]), _vm._v(" " + _vm._s(_vm.$t('form.action.addLocale')) + " ")], 1) : _vm._e(), _vm.hasUpdateRight ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.fileModalActive = true;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.icons.mdiSwapVertical))]), _vm._v(" " + _vm._s(_vm.$t('form.action.importExport')) + " ")], 1) : _vm._e()], 1), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "placeholder": _vm.$t('form.placeholder.search'),
      "prepend-inner-icon": _vm.icons.mdiMagnify,
      "outlined": ""
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)], 1), _c('CommonAutocomplete', {
    attrs: {
      "multiple": "",
      "label": _vm.$tc('form.field.locale', 2),
      "rules": [_vm.isRequired(_vm.displayLocales, _vm.$tc('form.field.locale', 2))],
      "items": _vm.implementedLocales,
      "prepend-inner-icon": _vm.icons.mdiEarth,
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-chip', {
          attrs: {
            "close": ""
          },
          on: {
            "click:close": function clickClose($event) {
              return _vm.removeSelectedDisplayLocale(item);
            }
          }
        }, [_vm.resolveCountry(item) !== '' ? _c('CommonFlagIcon', {
          staticClass: "mr-2",
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")], 1)];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item,
            on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_c('v-list-item-icon', {
          staticClass: "mr-2"
        }, [_vm.resolveCountry(item) !== '' ? _c('common-flag-icon', {
          attrs: {
            "locale": _vm.resolveCountry(item)
          }
        }) : _vm._e(), _vm.resolveCountry(item) === '' ? _c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm.$t("locale.".concat(item))) + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.displayLocales,
      callback: function callback($$v) {
        _vm.displayLocales = $$v;
      },
      expression: "displayLocales"
    }
  }), _vm.isLoading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('v-expansion-panels', {
    attrs: {
      "hover": "",
      "multiple": ""
    }
  }, _vm._l(_vm.filteredTranslationGroups, function (translationGroup) {
    return _c('translation-item', {
      key: translationGroup.key,
      attrs: {
        "display-locales": _vm.displayLocales,
        "translation-group": translationGroup
      },
      on: {
        "on-delete-translation": function onDeleteTranslation($event) {
          return _vm.loadGroupedTranslations();
        }
      }
    });
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "width": "600"
    },
    model: {
      value: _vm.isTranslationAddModalActive,
      callback: function callback($$v) {
        _vm.isTranslationAddModalActive = $$v;
      },
      expression: "isTranslationAddModalActive"
    }
  }, [_vm.isTranslationAddModalActive ? _c('add-translation', {
    on: {
      "close": function close($event) {
        _vm.isTranslationAddModalActive = false;
      },
      "translation-added": function translationAdded($event) {
        return _vm.loadGroupedTranslations();
      }
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "scrollable": "",
      "width": "600"
    },
    model: {
      value: _vm.isLocaleAddModalOpen,
      callback: function callback($$v) {
        _vm.isLocaleAddModalOpen = $$v;
      },
      expression: "isLocaleAddModalOpen"
    }
  }, [_vm.isLocaleAddModalOpen ? _c('add-locale', {
    on: {
      "close": function close($event) {
        _vm.isLocaleAddModalOpen = false;
      },
      "locale-added": function localeAdded($event) {
        return _vm.addLocaleToTranslations($event);
      }
    }
  }) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "scrollable": "",
      "width": "600"
    },
    model: {
      value: _vm.fileModalActive,
      callback: function callback($$v) {
        _vm.fileModalActive = $$v;
      },
      expression: "fileModalActive"
    }
  }, [_vm.fileModalActive ? _c('upload-locale-file', {
    on: {
      "close": function close($event) {
        _vm.fileModalActive = false;
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }