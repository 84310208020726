var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_vm.missingError ? _c('v-icon', {
    attrs: {
      "color": _vm.missingError
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiAlertOutline) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.translationGroup.key) + " ")], 1), _c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t(_vm.translationGroup.key)) + " ")], 1), _c('v-expansion-panel-content', {
    staticClass: "pt-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.updateTranslations.apply(null, arguments);
      }
    }
  }, [_vm._l(_vm.displayLocales, function (locale) {
    return _c('v-row', {
      key: locale,
      attrs: {
        "dense": ""
      }
    }, [_c('v-col', [_c('v-text-field', {
      attrs: {
        "label": _vm.$t("locale.".concat(locale)),
        "disabled": !_vm.hasTranslationUpdateRight,
        "outlined": ""
      },
      on: {
        "input": _vm.updateError
      },
      scopedSlots: _vm._u([{
        key: "prepend-inner",
        fn: function fn() {
          return [_vm.resolveCountry(locale) !== '' ? _c('CommonFlagIcon', {
            staticClass: "mr-2",
            attrs: {
              "locale": _vm.resolveCountry(locale)
            }
          }) : _vm._e(), _vm.resolveCountry(locale) === '' ? _c('v-icon', {
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(_vm.icons.mdiEarth))]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.translationInputs[locale],
        callback: function callback($$v) {
          _vm.$set(_vm.translationInputs, locale, $$v);
        },
        expression: "translationInputs[locale]"
      }
    })], 1)], 1);
  }), _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteTranslation(_vm.translationGroup.key);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.deleteTranslation')) + " ")]), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "color": "primary",
      "type": "submit",
      "loading": _vm.isLoading
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.saveTranslation')) + " ")])], 1)], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }