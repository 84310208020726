
import { defineComponent, ref, computed } from '@vue/composition-api'
import { mdiEarth, mdiAlertOutline, mdiMagnify, mdiPlus, mdiSwapVertical } from '@mdi/js'
import { debounce } from 'lodash-es'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useAuthGetters } from '@/store'

import { resolveCountry } from '@/plugins/i18n'

import { TranslationGroup } from '@/api/types/translation'
import { useGetTranslations } from '@/api/translation'
import { Rights } from '@/api/types/right'
import { isRequired } from '@/utils/validation'

import TranslationItem from '@/views/admin/translations/TranslationItem.vue'

export default defineComponent({
  name: 'AdminTranslationsView',
  components: {
    AddTranslation: () => import('@/views/admin/translations/AddTranslation.vue'),
    AddLocale: () => import('@/views/admin/translations/AddLocale.vue'),
    UploadLocaleFile: () => import('@/views/admin/translations/UploadLocaleFile.vue'),
    CommonFlagIcon,
    TranslationItem,
    CommonAutocomplete,
  },
  setup(props, { root }) {
    const { hasRight } = useAuthGetters()
    const hasApiDeveloperRight = computed(() => hasRight.value(Rights.API_DEVELOPER))
    const hasCreateRight = computed(() => hasRight.value(Rights.TRANSLATION_CREATE))
    const hasUpdateRight = computed(() => hasRight.value(Rights.TRANSLATION_UPDATE))

    /**
     * Translations
     */
    const { getTranslations, isLoading } = useGetTranslations()
    const translationGroups = ref<TranslationGroup[]>([])
    const filteredTranslationGroups = ref<TranslationGroup[]>([])

    const loadGroupedTranslations = async () => {
      const groupedTranslations = await getTranslations()

      translationGroups.value = Object.keys(groupedTranslations).map((key) => ({
        key,
        translations: groupedTranslations[key],
      }))
      filteredTranslationGroups.value = [...translationGroups.value]
    }
    loadGroupedTranslations()

    /**
     * Search / Filter
     */
    const _searchTerm = ref('')

    const searchTerm = computed({
      get: () => _searchTerm.value,
      set: (val) => {
        _searchTerm.value = val

        debounce(filterTranslationGroups, 300)()
      },
    })

    /**
     * Locales to display
     */
    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)
    const displayLocales = ref<string[]>([...implementedLocales.value])

    const removeSelectedDisplayLocale = (locale: string) => {
      const index = displayLocales.value.indexOf(locale)

      if (index >= 0) {
        displayLocales.value.splice(index, 1)
      }
    }

    const filterTranslationGroups = () => {
      if (!searchTerm.value || !searchTerm.value.length) {
        filteredTranslationGroups.value = translationGroups.value
      } else {
        filteredTranslationGroups.value = translationGroups.value.filter((group) =>
          group.key.includes(searchTerm.value)
        )
      }
    }

    /**
     * Add Translation
     */
    const isTranslationAddModalActive = ref(false)

    /**
     * Add Locale
     */
    const isLocaleAddModalOpen = ref(false)

    const addLocaleToTranslations = (locale: string) => {
      isLocaleAddModalOpen.value = false

      // display the new locale
      displayLocales.value.push(locale)

      // add empty values for the new locale
      translationGroups.value.forEach((group) =>
        group.translations.push({
          key: group.key,
          locale: locale,
          value: '',
        })
      )
    }

    /**
     * Import / Export
     */
    const fileModalActive = ref(false)

    return {
      icons: { mdiAlertOutline, mdiMagnify, mdiPlus, mdiSwapVertical, mdiEarth },
      isRequired,
      isLoading,
      fileModalActive,
      filteredTranslationGroups,
      displayLocales,
      implementedLocales,
      removeSelectedDisplayLocale,
      searchTerm,
      isTranslationAddModalActive,
      isLocaleAddModalOpen,
      loadGroupedTranslations,
      addLocaleToTranslations,
      hasApiDeveloperRight,
      hasCreateRight,
      hasUpdateRight,
      resolveCountry,
    }
  },
})
