
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash-es'
import { mdiAlertOutline, mdiEarth } from '@mdi/js'

import CommonFlagIcon from '@/components/common/CommonFlagIcon.vue'

import { reloadLanguage, resolveCountry } from '@/plugins/i18n'

import { useNotify, useAuthGetters } from '@/store'

import { TranslationGroup } from '@/api/types/translation'
import { Rights } from '@/api/types/right'

import { translationsApi } from '@/api'

export default defineComponent({
  name: 'TranslationItem',
  components: {
    CommonFlagIcon,
  },
  props: {
    displayLocales: {
      type: Array as PropType<string[]>,
      required: true,
    },
    translationGroup: {
      type: Object as PropType<TranslationGroup>,
      reqired: true,
      default: () => ({
        key: '',
        translations: [],
      }),
    },
  },
  setup: (props, { root, emit }) => {
    const { hasRight } = useAuthGetters()
    const hasTranslationUpdateRight = hasRight.value(Rights.TRANSLATION_UPDATE)

    const { updateTranslation, isLoading } = translationsApi.useUpdateTranslation()
    const { deleteTranslation: deleteTranslationXhr } = translationsApi.useDeleteTranslation()

    const { addNotification } = useNotify()

    const translationInputs = ref<{ [key: string]: string }>({})

    const implementedLocales = computed<string[]>(() => root.$store.state.locale.implementedLocales)

    watch(
      implementedLocales,
      (val) => {
        for (const locale of val) {
          translationInputs.value[locale] =
            props.translationGroup.translations.find((translation) => translation.locale === locale)?.value || ''
        }
      },
      { immediate: true }
    )

    const missingError = ref('')

    const updateError = debounce(
      () => {
        const translations = Object.entries(translationInputs.value)
          .filter(([key]) => props.displayLocales.includes(key))
          .map(([, value]) => value)

        missingError.value = translations.every((translation) => !translation.trim().length)
          ? 'error'
          : translations.some((translation) => !translation.trim().length)
          ? 'warning'
          : ''
      },
      1000,
      { leading: true }
    )

    watch(
      () => props.displayLocales,
      () => updateError(),
      { immediate: true }
    )

    const updateTranslations = () => {
      const translationsToUpdate = Object.entries(translationInputs.value).filter(
        ([key, value]) => props.displayLocales.includes(key) && value.trim()
      )

      const promises = translationsToUpdate.map(([key, value]) =>
        updateTranslation({ key: props.translationGroup.key, value: value, locale: key })
      )

      Promise.all(promises)
        .then(() => {
          addNotification({
            type: 'success',
            text: root.$t('translations.update.success') as string,
          }).then(() => {
            reloadLanguage()
          })
        })
        .catch((error) => {
          error.userMessage = root.$t('translations.update.error')

          throw error
        })
    }

    const deleteTranslation = (key: string) => {
      deleteTranslationXhr(key).then(() => {
        addNotification({
          type: 'success',
          text: root.$t('translations.delete.success', {
            key,
          }) as string,
        })

        reloadLanguage()

        emit('on-delete-translation')
      })
    }

    return {
      icons: { mdiAlertOutline, mdiEarth },
      test: 0,
      isLoading,
      translationInputs,
      missingError,
      updateError,
      updateTranslations,
      deleteTranslation,
      resolveCountry,
      hasTranslationUpdateRight,
    }
  },
})
